<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="justify-content-between">
          <b-col cols="3">
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                id="branch-site"
                ref="branch-site"
                v-model="selectedSite"
                :options="authSite"
                class="font-small-3"
                style="color:rgb(229,166,48);"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="3"
          >
            <b-form-group
              label="총판"
              label-for="branch"
            >
              <b-form-select
                id="branch"
                ref="branch"
                v-model="branchSelected"
                :options="branchSelectOptions"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
          >
            <b-input-group
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <div
              class="text-info small pt-1"
              style="padding-left: 2rem"
            >
              Z: 이월되는 Z`의 값
            </div>
            <div
              class="text-info small"
              style="padding-left: 2rem"
            >
              Z`: 총판포함 하위캐쉬 + 총판포함 하위카지노캐쉬 + 총판포함 하위포인트
            </div>
          </div>
        </div>
        <b-row class="m-1">
          <b-col>
            <b-table
              ref="branchDailyTable"
              responsive
              striped
              hover
              small
              show-empty
              empty-text="조회내역이 없습니다."
              thead-class="text-center"
              tbody-class="text-center"
              sort-by="baseDate"
              :sort-desc="false"
              :items="branchGroupItems"
              :fields="branchGroupFields"
              :tbody-transition-props="transProps"
            >
              <template #thead-top>
                <b-tr>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 10rem;"
                  >
                    총판명
                  </b-th>
                  <b-th
                    colspan="2"
                    class="align-middle"
                    style="font-size: small; "
                  >
                    현재머니
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    입출금
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    충-환<br>
                    <span style="font-size: xx-small;">(B=X-Y)</span>
                  </b-th>
                  <b-th
                    colspan="6"
                    class="text-center align-middle"
                  >
                    롤링
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem; background-color: #21315a;"
                  >
                    롤링금 합계<br>
                    <span style="font-size: xx-small;">(C=W+Y+Z)</span>
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    루징
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="text-center align-middle"
                    style="background-color: #21315a;"
                  >
                    루징금<br>합계<br>
                    <span style="font-size: xx-small;"> (F=D+E) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br> 누적 루징금<br>
                    <span style="font-size: xx-small;"> (G=F+F`) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금액<br>
                    <span style="font-size: xx-small;"> (H) </span>
                  </b-th>
                  <b-th
                    rowspan="3"
                    class="align-middle"
                    style="font-size: small; min-width: 5rem;"
                  >
                    총판<br>출금가능<br>
                    <span style="font-size: xx-small;"> (I=G-H) </span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    캐쉬
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    포인트
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    충전<br>
                    <span style="font-size: xx-small;">(M)</span>
                  </b-th>
                  <b-th
                    rowspan="2"
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    환전<br>
                    <span style="font-size: xx-small;">(N)</span>
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    스포츠
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    카지노
                  </b-th>
                  <b-th
                    colspan="2"
                    class="text-center align-middle"
                  >
                    슬롯
                  </b-th>
                  <b-th
                    colspan="3"
                    class="text-center align-middle"
                  >
                    <span style="font-size: xx-small;">D,E=(B-A-C)*R</span>
                  </b-th>
                </b-tr>
                <b-tr>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(W)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(X)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    배팅금
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    롤링금
                    <span style="font-size: xx-small;">(Y)</span>
                  </b-th>

                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    요율
                    <span style="font-size: xx-small;">(R)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    자신
                    <span style="font-size: xx-small;">(D)</span>
                  </b-th>
                  <b-th
                    class="text-center align-middle"
                    style="font-size: small; "
                  >
                    하위
                    <span style="font-size: xx-small;">(E)</span>
                  </b-th>
                </b-tr>
              </template>
              <template #cell(curCash)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'캐쉬: ' + Number(data.item.curCash).toLocaleString() + ' (+' + Number(data.item.curLowerCash).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.curCash + data.item.curLowerCash).toLocaleString() }}
                </div>
              </template>
              <template #cell(curPoint)="data">
                <div
                  v-b-tooltip.hover.bottom.html="'포인트: ' + Number(data.item.curPoint).toLocaleString() + ' (+' + Number(data.item.curLowerPoint).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.curPoint + data.item.curLowerPoint).toLocaleString() }}
                </div>
              </template>

              <template #cell(deposit)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.deposit).toLocaleString() + '(+' + Number(data.item.lowerDeposit).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.deposit + data.item.lowerDeposit).toLocaleString() }}
                </div>
              </template>
              <template #cell(exchange)="data">
                <div
                  v-b-tooltip.hover.bottom.html="Number(data.item.exchange).toLocaleString() + '(+' + Number(data.item.lowerExchange).toLocaleString() + ')'"
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.exchange + data.item.lowerExchange).toLocaleString() }}
                </div>
              </template>
              <template
                #cell(balance)="data"
              >
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem; "
                >
                  {{ Number((data.item.deposit + data.item.lowerDeposit) - (data.item.exchange + data.item.lowerExchange)).toLocaleString() }}
                </div>
              </template>

              <template #cell(rolling)="data">
                <div
                  class="text-right"
                  style="font-size: small; min-width: 6rem;"
                >
                  {{ Number(data.item.totalSportRollingAmount + data.item.totalCasinoRollingAmount + data.item.totalSlotRollingAmount ).toLocaleString() }}
                </div>
              </template>
              <template #cell(type)="data">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="btn-icon"
                  size="sm"
                  :variant="data.item.type === 'ok' ? 'secondary' : 'primary'"
                  :disabled="data.item.type === 'ok'"
                  @click="updateStatusCommission(data.item)"
                >
                  <feather-icon
                    :icon="data.item.type === 'ok' ? 'CheckIcon' : 'DollarSignIcon'"
                    size="14"
                  />
                </b-button>
              </template>
              <template
                v-if="branchGroupItems.length > 0"
                slot="bottom-row"
              >
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('deposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('exchange')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('exchange')-getTotal('deposit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalCasinoBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalCasinoRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSlotBettingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('totalSportRollingAmount') + getTotal('totalCasinoRollingAmount') + getTotal('totalSlotRollingAmount')).toLocaleString() }}
                </td>
                <td
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('profit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accLowerProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('sumProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('accProfit')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Number(getTotal('withdrawnAmount')).toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ Math.abs(Number(getTotal('withdrawableAmount'))).toLocaleString() }}
                </td>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BTable,
  BTr,
  BTh,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormDatepicker,
  BCardText,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_BRANCH_SELECT_OPTIONS,
  FETCH_BRANCH_BALANCE_GROUP,
} from '@/store/branch/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD'

const branchStore = createNamespacedHelpers('branchStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCalculateGroup',

  components: {
    BCard,
    BCardBody,
    BTable,
    BTr,
    BTh,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormDatepicker,
    BCardText,
    BFormTextarea,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      userData: JSON.parse(localStorage.getItem('userData')),
      authSite: localStorage.getItem('authSite').split(','),
      selectedSite: localStorage.getItem('authSiteSelected'),
      branchSelected: null,

      cashOutBranchName: JSON.parse(localStorage.getItem('userData')).nickname,
      cashOutBranchDate: moment(new Date()).subtract(1, 'days').format(fmt3),
      cashOutBranchAvailable: 0,
      cashOutBranchAmount: 0,
      cashOutBranchComment: '',
      branchGroupItems: [],
      branchGroupFields: [
        { key: 'branch', thClass: 'd-none' },
        { key: 'curCash', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'curPoint', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'deposit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'exchange', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSportBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSportRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalCasinoBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalCasinoRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSlotBettingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'totalSlotRollingAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rolling', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rate', thClass: 'd-none' },
        { key: 'profit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'accLowerProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'sumProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 루징금합계
        { key: 'accProfit', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' }, // 총판 누적 루징금
        { key: 'withdrawnAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'withdrawableAmount', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
      ],
      periodFrom: '',
      periodTo: '',

      transProps: {
        name: 'fade',
        mode: 'in-out',
      },
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      fetchBranchBalanceGroup: 'fetchBranchBalanceGroup',
    }),
    branchSelectOptions() {
      const resultData = this.fetchBranchSelectOptions.map(option => ({
        value: option,
        text: option.status === 'active'
          ? `[${option.role}] ${option.nickname}`
          : `[${option.role}] ${option.nickname} (${option.status})`,
        selected: option.userid === this.userData.userid,
      }))
      this.branchSelected = this.fetchBranchSelectOptions.find(option => option.userid === this.userData.userid)
      return resultData
    },
  },
  watch: {
    selectedSite: {
      handler(event) {
        localStorage.setItem('authSiteSelected', event || localStorage.getItem('authSiteSelected'))
        this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
        this.init()
      },
      immediate: false,
      deep: false,
    },
    branchSelected: {
      handler() {
        this.fetchData()
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchBalanceGroup: FETCH_BRANCH_BALANCE_GROUP,
    }),
    async init() {
      // this.periodFrom = `${new Date().toISOString().slice(0, 8)}01`
      // this.periodTo = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10)
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)

      await this.$fetchBranchSelectOptions({
        site: this.selectedSite,
        userid: this.userData.userid,
      })
      await this.fetchData()
    },
    searchData() {
      this.fetchData()
    },
    async fetchData() {
      await this.$fetchBranchBalanceGroup({
        userid: this.branchSelected.userid,
        site: this.selectedSite,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      this.branchGroupItems = this.fetchBranchBalanceGroup
    },

    /* [formatter] */
    formatNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value, 10).toLocaleString()
    },
    getDateWithDash(value) {
      if (value == null) {
        return ''
      }
      return moment(value, 'YYYYMMDDHHmmss').format(fmt3)
    },
    getTotal(field) {
      return this.branchGroupItems.reduce((acc, item) => acc + item[field], 0)
    },
  },
}

</script>

<style scoped>

.b-table > thead > tr > th {
  border-right: solid 1.5px #3b4253 !important;
  border-bottom: solid 1.5px #3b4253 !important;
  min-width: 6rem;
  /* white-space: no-wrap !important; */
}
.b-table > thead > tr > th:last-child {
  border-right: none !important;
}

.per-page-selector {
  width: 90px;
}
.category-select {
  width: 80px;
}
.b-table .table > thead > tr > th {
  white-space: pre-wrap !important;
}
</style>
